import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:5005';
axios.defaults.baseURL = 'https://api.locates.com.br';

axios.interceptors.request.use(function (config) {
	let loginData = JSON.parse(localStorage.getItem('login-data'));
	let token = '';

	if (loginData != null) {
		token = loginData.token;
		config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common.UserId = loginData.login;
		config.headers.common.UserCode = loginData.user_id;
	}

	return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
